import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const InjectiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M22.31 9.26199L22.244 9.39389C24.532 10.3831 25.83 12.4274 25.83 14.6696C25.83 17.0877 24.246 19.2859 21.166 21.1544L20.66 21.4621C18.35 22.869 17.184 24.4517 17.184 26.3641C17.184 28.8261 19.12 30.6286 21.782 30.6286C26.05 30.6286 31 26.0784 31 20.0113C31 19.0221 30.868 18.0549 30.626 17.1316L30.472 17.1756C30.538 17.6812 30.56 18.0549 30.56 18.4066C30.56 21.9897 28.536 25.0672 25.148 27.1115L24.818 27.3094C24.092 27.727 23.476 27.9688 22.86 27.9688C22.046 27.9688 21.43 27.4413 21.43 26.6719C21.43 26.0124 21.87 25.353 23.3 24.5176L23.718 24.2758C26.886 22.4293 28.646 19.8354 28.646 16.9558C28.646 13.3067 25.918 10.2952 22.31 9.26199ZM17.69 30.7386L17.756 30.6067C15.468 29.6175 14.17 27.5732 14.17 25.331C14.17 22.9129 15.754 20.7147 18.834 18.8462L19.34 18.5385C21.65 17.1316 22.816 15.5489 22.816 13.6364C22.816 11.1744 20.88 9.3719 18.218 9.3719C13.95 9.3719 9 13.9222 9 19.9893C9 20.9785 9.132 21.9457 9.374 22.869L9.528 22.825C9.462 22.3194 9.44 21.9457 9.44 21.594C9.44 18.0109 11.464 14.9334 14.852 12.8891L15.182 12.6912C15.908 12.2736 16.524 12.0317 17.14 12.0317C17.954 12.0317 18.57 12.5593 18.57 13.3287C18.57 13.9882 18.13 14.6476 16.7 15.483L16.282 15.7248C13.114 17.5713 11.354 20.1652 11.354 23.0448C11.354 26.6939 14.082 29.7054 17.69 30.7386Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default InjectiveIcon
