var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChainId } from "@injectivelabs/ts-types";
export const isInjectiveChainId = (chainId) => {
    switch (chainId) {
        case ChainId.Mainnet:
        case ChainId.Testnet:
        case ChainId.Devnet:
            return true;
        default:
            return false;
    }
};
export const isInjectiveMainnet = (network) => __awaiter(void 0, void 0, void 0, function* () {
    const { Network } = yield import("@injectivelabs/networks");
    return network === Network.MainnetSentry;
});
export const chainIdToInjectiveChainId = (chainId) => {
    switch (chainId) {
        case ChainId.Mainnet:
            return ChainId.Mainnet;
        case ChainId.Testnet:
            return ChainId.Testnet;
        case ChainId.Devnet:
            return ChainId.Devnet;
        default:
            return null;
    }
};
export const chainIdToInjectiveNetwork = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    const { Network } = yield import("@injectivelabs/networks");
    switch (chainId) {
        case ChainId.Mainnet:
            return Network.MainnetSentry;
        case ChainId.Testnet:
            return Network.Testnet;
        case ChainId.Devnet:
            return Network.Devnet;
        default:
            return null;
    }
});
export const chainIdToInjectiveNetworkWithDefault = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { Network } = yield import("@injectivelabs/networks");
    return (_a = (yield chainIdToInjectiveNetwork(chainId))) !== null && _a !== void 0 ? _a : Network.Testnet;
});
