import * as Sentry from "@sentry/react"
import type { Primitive } from "@sentry/types"
import type { TFunction } from "i18next"

import { AppError, type SeverityLevel } from "@future/libs/error/AppError"

export interface Breadcrumb
  extends Pick<Sentry.Breadcrumb, "category" | "data" | "level" | "message"> {
  // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  type?:
    | "default"
    | "user"
    | "query"
    | "navigation"
    | "http"
    | "info"
    | "error"
    | "debug"
}

export type Tag =
  | "connector.id"
  | "contract.error.id"
  | "error.code"
  | "factory"
  | "target"
  | "transaction.id"

// biome-ignore lint/complexity/noStaticOnlyClass: by design
export abstract class Report {
  private static enabled = true

  static disableReporting = () => {
    this.enabled = false
  }

  static enableReporting = () => {
    this.enabled = true
  }

  static error = (error: Error, t?: TFunction<"error">) => {
    if (error instanceof AppError) {
      // Stringify to remove unwanted props, parse to make it easier to read
      Report.log(JSON.parse(JSON.stringify(error)), error.level)
    } else {
      Report.log(error, "error")
    }

    if (!this.enabled) {
      return
    }

    Sentry.withScope((scope) => {
      if (error instanceof AppError) {
        if (error.level === "suppress") {
          return
        }

        scope.setTag("transaction.id", error.transactionId)
        scope.setLevel(error.level)
        scope.addBreadcrumb({
          level: "debug",
          type: "debug",
          category: "context",
          data: {
            context: error.toContexts(t),
            extra: error.extra,
          },
        })

        // Previously the root cause error was returned here to improve clarity
        // in the Sentry report. However some of the AppError data is required
        // for rate limiting errors. Now this logic has been moved into the
        // Sentry config `beforeSend` function.
      }

      Sentry.captureException(error)
    })
  }

  static object = (error: unknown, level?: Sentry.SeverityLevel) => {
    if (error instanceof Error) {
      this.error(error)
      return
    }

    if (!this.enabled) {
      return
    }

    try {
      const message = JSON.stringify(error)
      Report.log(message, level)
      Sentry.captureException(message, { level })
    } catch {
      Report.log(error, level)
      Sentry.captureException(error, { level })
    }
  }

  static message = (message: string, level?: Sentry.SeverityLevel) => {
    Report.log(message, level)

    if (!this.enabled) {
      return
    }

    Sentry.captureMessage(message, level)
  }

  static addBreadcrumb = (breadcrumb: Breadcrumb) => {
    if (!this.enabled) {
      return
    }

    Sentry.addBreadcrumb(breadcrumb)
  }

  static setTag = (key: Tag, value: Primitive) => {
    if (!this.enabled) {
      return
    }

    Sentry.setTag(key, value)
  }

  private static log = (message: unknown, level: SeverityLevel = "log") => {
    switch (level) {
      case "debug":
        console.debug(message)
        break
      case "log":
        console.log(message)
        break
      case "info":
        console.info(message)
        break
      case "warning":
        console.warn(message)
        break
      case "error":
      case "fatal":
      case "suppress":
        console.error(message)
        break
    }
  }
}

/**
 * Breadcrumb data is not always able to be inspected after a couple dimensions.
 * This utility function stringifies all values to resolve this issue.
 */
export const stringifyBreadcrumbData = (data: Breadcrumb["data"]) => {
  if (!data) {
    return data
  }

  return Object.entries(data).reduce(
    (accumulator, [key, value]) => {
      if (value === undefined) {
        return accumulator
      }
      try {
        accumulator[key] =
          typeof value === "string" ? value : JSON.stringify(value)
      } catch {
        accumulator[key] = value
      }
      return accumulator
    },
    {} as Record<string, string>,
  )
}
